"use strict";

import {on,addClass} from "@c0necto/elements-dom-utils";
import fetch from "@c0necto/elements-fetch";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {
    let promiseChain = Promise.resolve(import('lightgallery.js'));

    promiseChain = promiseChain.then(() => {
        Promise.resolve(import('lg-thumbnail.js'))
    });

    promiseChain.then(() => {
        onFind('.js-lightbox-dynamic', function (lightboxDynItem) {
            const lightboxClickHandler = (evt, lightboxDynItem) => {
                evt.preventDefault();

                let url = lightboxDynItem.getAttribute('href');

                let index = lightboxDynItem.getAttribute('data-dynamic-lightbox-index') || 0;

                let request = fetch(url, {
                    method: 'get'
                });

                request.then(response => response.json())
                    .then(result => {
                        if (result.success) {
                            lightGallery(lightboxDynItem, {
                                thumbnail:true,
                                dynamic: true,
                                dynamicEl: result.content,
                                index: index,
                                download:false

                            });
                        }
                    }).catch(e => {
                    console.warn(e);
                });
            };

            addClass('lightbox-is-init',lightboxDynItem);

            on('click', (evt) => lightboxClickHandler(evt, lightboxDynItem), lightboxDynItem);
        });
    });

}