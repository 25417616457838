"use strict";

import * as leafletMap from "@c0necto/elements-leaflet-map";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {onEnterViewPort} from "@c0necto/elements-viewport-utils";

export function init () {
    onFind('.js-leaflet-map', function (mapElement) {

        let poiVarName = mapElement.dataset.leafletMapPois;
        let disableClustering = mapElement.dataset.disableClustering;
        let setClustering = true;

        if (disableClustering){
            setClustering = false;
        }
        let pois = [];

        if (poiVarName) {
            if (!window['_leafletMapPois']) {
                console.error('_leafletMapPois is not set');
            } else if (!window._leafletMapPois[poiVarName]) {
                console.error(poiVarName + ' is not set in _leafletMapPois', _leafletMapPois);
            } else {
                pois = _leafletMapPois[poiVarName];
            }
        }

        const infoBoxOptions = {
            maxWidth: matchMedia('(max-width: 767px)').matches ? 250 : 320,
            minWidth: matchMedia('(max-width: 767px)').matches ? 230 : 300,
            className: 'map-infobox'
        };

        onEnterViewPort(mapElement, function (mapElement) {
            leafletMap.init({
                infoBoxOptions,
                tilelayer: 'https://maps{s}.wien.gv.at/basemap/geolandbasemap/{type}/google3857/{z}/{y}/{x}.{format}',
                leafletOptions:  {
                    attribution: 'Datenquelle: <a href="https://www.basemap.at">basemap.at</a>',
                    subdomains: ["", "1", "2", "3", "4"],
                    type: 'normal',
                    format: 'png',
                    bounds: [[46.35877, 8.782379], [49.037872, 17.189532]]
                },
                mapOptions: {
                    zoom: 15,
                    maxZoom: 18,
                },
                pois: pois,
                poiStyles: () => createPoiStyles(),
                onActivateMarker: (marker, api) => onActivateMarker(marker, api),
                onDeactivateMarker: (marker, api) => onDeactivateMarker( marker, api),
                clustering: setClustering,
                clusteringOptions: () => {
                    return {
                        showCoverageOnHover: false,
                        iconCreateFunction: function(cluster) {
                            return L.divIcon({
                                iconSize: [32, 52],
                                iconAnchor: [16, 26],
                                className: 'marker-cluster',
                                html: '<img class="marker-cluster__img" src="/static/img/map/cluster.png"/><span class="marker-cluster__count">' + cluster.getChildCount() + '</span>'
                            });
                        }
                    };
                }
            });


            leafletMap.getApi(mapElement).then((api) => {
                // let mainPoi = JSON.parse(mapElement.getAttribute('data-main-poi'));
                let mainPoi;

                pois.map(poi => {
                    if(poi.poiStyle === 'main'){
                        mainPoi = poi;
                    }
                });
                if(mainPoi){
                    let searchPoi = [{lat: mainPoi.lat, lng: mainPoi.lng, poiStyle: 'main'}];
                    let searchPoiLatLng = [{_latlng: {lat: mainPoi.lat, lng: mainPoi.lng}}];

                    api.addMarker(searchPoi);
                    // api.scrollToMarkers(searchPoiLatLng, 14);
                }

                api.centerMap();
            });
        }, { offset: 300 });

    });
}
function createPoiStyles() {

    let iconDefaultUrl = '/static/img/map/marker-uab.png';
    let iconActiveUrl = '/static/img/map/marker-uab-highlighted.png';

    if(_config.pzg){
        iconDefaultUrl = '/static/img/map/pzg/marker-pzg.svg';
        iconActiveUrl = '/static/img/map/pzg/marker-pzg-highlighted.svg';
    }

    return {
        main: {
            default: L.icon({
                iconUrl: iconDefaultUrl,
                iconSize: [56, 82],
                iconAnchor: [28, 82],
                popupAnchor: [0, -80],
            }),
            active: L.icon({
                iconUrl: iconActiveUrl,
                iconSize: [56, 82],
                iconAnchor: [28, 82],
                popupAnchor: [0, -80],
            })

        },
        basic: {
            default: L.icon({
                iconUrl: iconDefaultUrl,
                iconSize: [32, 48],
                iconAnchor: [16, 48],
                popupAnchor: [0, -65],
            }),
            active: L.icon({
                iconUrl: iconActiveUrl,
                iconSize: [32, 48],
                iconAnchor: [16, 48],
                popupAnchor: [0, -65],
            })
        },
        direct: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-direct.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-direct.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            })
        },
        market: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-market.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-market.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],

            })
        },
        shop: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-shop.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-shop.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 58],
                popupAnchor: [0, -55],
            })
        },
        bar: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-bar.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-bar.svg',
                iconSize: [26, 41],
                iconAnchor: [13, 41],
                popupAnchor: [0, -55],
            })
        },
        basicCrown: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-direct-crown.svg',
                iconSize: [30, 53],
                iconAnchor: [15, 53],
                popupAnchor: [0, -65],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-direct-crown.svg',
                iconSize: [30, 53],
                iconAnchor: [15, 53],
                popupAnchor: [0, -65],
            })
        },
        basicBio: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-direct-bio.svg',
                iconSize: [38, 45],
                iconAnchor: [19, 45],
                popupAnchor: [-8, -55],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-direct-bio.svg',
                iconSize: [38, 45],
                iconAnchor: [19, 45],
                popupAnchor: [-8, -55],
            })
        },
        basicBioCrown: {
            default: L.icon({
                iconUrl: '/static/img/map/marker-direct-bio-crown.svg',
                iconSize: [42, 55],
                iconAnchor: [21, 55],
                popupAnchor: [0, -72],
            }),
            active: L.icon({
                iconUrl: '/static/img/map/marker-direct-bio-crown.svg',
                iconSize: [42, 55],
                iconAnchor: [21, 55],
                popupAnchor: [0, -72],
            })
        }
    };

}

function onActivateMarker(marker, api) {
    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = api.showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`
        );

        let contentPromise = fetch(marker.detailInfoBoxUrl, {
            method: 'GET',
            headers: {"content-type": "application/json"}
        }).then((res) => {
            return res.json()
        });


        Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
            marker.bindPopup(response.content).openPopup();
        });
    }
}

function onDeactivateMarker(marker, mapObj) {
    // let detailArea = $('.js-leaflet-map__detail-area');
    // detailArea.html("");
}

