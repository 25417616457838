"use strict";

import {findIn, on} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {
    onFind('.js-calculate-route', function (element) {
        const submitHandler = (evt, calculateRoute) => {
            evt.preventDefault();

            let destinationAddress = calculateRoute.dataset.destinationAddress;
            let calculateRouteValue = findIn('.form-control', calculateRoute).value;
            let googleMapsRouteUrl = 'https://www.google.com/maps/dir/?api=1&origin=' + calculateRouteValue + '&destination=' + destinationAddress;

            window.open(googleMapsRouteUrl, '_blank');
        };

        on('submit', (evt) => submitHandler(evt, element), element);
    });
}