"use strict";

import {findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {createSwiper} from "./swiper";

export function init () {

    onFind('.js-swiper-availability', function (sliderElement) {

        let initialSlide = sliderElement.getAttribute('data-swiper-availability-initial-slide') || 0;

        createSwiper(sliderElement,{
                // mediaQuery: '(max-width: 767px)',
                loop: false,
                slidesPerView: 1,
                spaceBetween: 20,
                effect: 'fade',
                initialSlide: initialSlide,
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    findNextElement: swiperContainer => findIn('.js-swiper-availability__next', swiperContainer),
                    findPrevElement: swiperContainer => findIn('.js-swiper-availability__prev', swiperContainer),
                },
                on: {
                    init: function () {
                        setTimeout(() => {
                            window.dispatchEvent(new CustomEvent("resize", {bubbles: true}));
                        }, 200)
                    },
                }
            },
            {
                sliderContainer: '.js-swiper-availability',
                slider: '.js-swiper-availability__slider',
                sliderPrev: '.js-swiper-availability__prev',
                sliderNex: '.js-swiper-availability__next'
            });

    });

}