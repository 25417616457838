"use strict";

import {findAllIn, findIn, on, closest, addClass, removeClass} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {

    onFind('.js-filter-dropdown', function (filterDropdown) {
        let filterDropdownInput = findAllIn('.js-filter-dropdown__input', filterDropdown);

        const changeHandler = (evt, inputCheckbox) => {
            setCounter(inputCheckbox, closest('.js-filter-dropdown', inputCheckbox), true)
        };

        filterDropdownInput.forEach((inputCheckbox) => {
            setCounter(inputCheckbox, filterDropdown, false);

            on('change', (evt) => changeHandler(evt, inputCheckbox), inputCheckbox);
        });
    });

}

function setCounter(inputCheckbox, filterDropdown, changed) {
    let filterDropdownCounterWrapper = findIn('.js-filter-dropdown__counter-wrapper', filterDropdown),
        filterDropdownCounter = findIn('.js-filter-dropdown__counter-value', filterDropdown);

    let filterDropdownCounterValue = parseInt(filterDropdownCounter.innerHTML);

    if (inputCheckbox.checked === true){
        filterDropdownCounterValue = filterDropdownCounterValue+1;
    }else if(changed && inputCheckbox.checked === false){
        filterDropdownCounterValue = filterDropdownCounterValue-1;
    }

    filterDropdownCounter.innerHTML = filterDropdownCounterValue;

    if (filterDropdownCounterValue > 0){
        removeClass('d-none', filterDropdownCounterWrapper);
    }else{
        addClass('d-none', filterDropdownCounterWrapper);
    }
}