"use strict";

import {forceRecalculation} from "@c0necto/elements-affix";
import Tab from 'bootstrap.native/dist/components/tab-native.esm';

export function init () {
    document.querySelectorAll('[data-toggle="tab"]').forEach(function (element) {
        new Tab(element);

        element.addEventListener('show.bs.tab', function(event){
            forceRecalculation();
        }, false);
    });
}
