"use strict";

import {find,findIn} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";

export function init () {
    onFind('[data-toggle="collapse"]',function (element) {
        let collapseElementId = element.getAttribute('href');
        let collapseElement = find(collapseElementId);

        collapseElement.addEventListener('show.bs.collapse', function (evt) {
            let trustyouIframe = findIn('.js-trust-you-iframe', evt.target);
            if (trustyouIframe){
                let iframeSrc = trustyouIframe.getAttribute('src');
                trustyouIframe.setAttribute('src', iframeSrc);
            }
        });
    });

    setTimeout(() => {
        let trustYouTarget = find('.js-trustyou-anchor-target').getAttribute('id');

        find('.js-trust-you-anchor-link').setAttribute('href', '#' + trustYouTarget);
    }, 500)


}