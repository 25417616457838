"use strict";
import * as smoothScroll from "./smooth-scroll";
import initModulesInScope, {onFind} from "@c0necto/elements-init-modules-in-scope";
import {find, findAllIn, findAll, findIn,hasClass, removeClass} from "@c0necto/elements-dom-utils";
import {getPrefixedDataSet} from '@c0necto/elements-data-set-utils';
import ScrollSpy from 'bootstrap.native/dist/components/scrollspy-native.esm';

const defaultSelectors = {
    list: '.js-anchor-nav__list',
    item: '.js-anchor-nav__item'
};

const defaultOptions = {
    renderItem: function (text, anchor) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link" href="#' + anchor + '"><span>' + text + '</span></a></li>';
    },
    smoothScroll: false,
    scrollSpy: false,
    scrollSpyTarget: '',
    scrollSpyOffset: 10,
    scrollSpyMethod: 'auto'
};

let elementsOptions;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.list, function (baseElement) {

        let tabPanes = findAll('.tab-pane');
        tabPanes.forEach((tabPane) => {
            if (!hasClass('active',tabPane)){
                let anchorNavItems = findAllIn('.js-anchor-nav__item', tabPane);

                anchorNavItems.forEach((item) => {
                    removeClass('js-anchor-nav__item', item);
                });

            }
        });

            createAnchorNav(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

}

export function createAnchorNav(baseElement, options = defaultOptions, selectors = defaultSelectors) {

    elementsOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('anchor-nav', baseElement)
    };

    if(elementsOptions.scrollSpy){

        let body = document.getElementsByTagName('body')[0];
        let scrollspyTarget = null;

        if (elementsOptions.scrollSpyTarget){
            scrollspyTarget = find(elementsOptions.scrollSpyTarget);
        }

        new ScrollSpy(body, {
            // set options
            target: scrollspyTarget,
            offset: elementsOptions.scrollSpyOffset,
            method: elementsOptions.scrollSpyMethod
        })
    }

    let list = baseElement,
        listItem = findAllIn(selectors.item, document.getElementsByTagName('body')[0]),
        itemsArr = [];

    listItem.forEach((listItem) => {
        let title = listItem.dataset.anchorNavTitle,
            position = listItem.dataset.anchorNavPosition | '',
            titleForAnchor = title.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').toLowerCase();


        if(listItem.getAttribute('id')){

            let newItem = {list: list, text: title, anchor: listItem.getAttribute('id'), position: position};

            if (position !== 0){
                itemsArr.splice(position-1, 0, newItem);
            }else{
                itemsArr.push(newItem)
            }
        }else{
            listItem.setAttribute('id', titleForAnchor);
            let newItem = {list: list, text: title, anchor: titleForAnchor, position: position};

            if (position !== 0){
                itemsArr.splice(position-1, 0, newItem);
            }else{
                itemsArr.push(newItem)
            }
        }

    });

    addNavItem(itemsArr);


    if(elementsOptions.smoothScroll){
        smoothScroll.init();
    }

}

function addNavItem(items) {
    items.map(item => {
        item.list.insertAdjacentHTML('beforeend', elementsOptions.renderItem(item.text, item.anchor));
        initModulesInScope(item.list.lastChild);

        if(findIn(`[href='#${item.anchor}']`,item.list).length < 1) {
            item.list.append(elementsOptions.renderItem(item.text, item.anchor))
        }
    });
}
