import * as polyfills from "./scripts/polyfills/ie";

import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

/*
import Popover from 'bootstrap.native/dist/components/popover-native.esm';
onFind('[data-content]',function (element) {
    new Popover(element);
});
*/

import './scripts/common';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/********************************
 *
 *      Project Stuff
 *
 * ********************************/
import {onFind} from "@c0necto/elements-init-modules-in-scope";

import * as lightbox from './scripts/lightbox';
lightbox.init({
    thumbnail:false,
    closable:true,
    closeOnTap: true,
    hideBarsDelay: 3000
});

import * as lightboxDynamic from './scripts/lightbox-dynamic';
lightboxDynamic.init({
    closable:true
});

import * as animationMoveUp from './scripts/animation-move-up';
animationMoveUp.init();

import * as partnerSlideSwiper from './scripts/partner-slide-swiper';
partnerSlideSwiper.init();

import * as availabilitySwiper from './scripts/availability-swiper';
availabilitySwiper.init();

import * as ajaxForm from '@c0necto/elements-ajax-form';
ajaxForm.init();

import * as filterDropdown from './scripts/filter-dropdown';
filterDropdown.init();

import * as video from './scripts/video';
video.init();

import * as showAllContent from './scripts/show-all-content';
showAllContent.init();

import * as calculateRoute from './scripts/calculate-route';
calculateRoute.init();

import * as iframeResizer from './scripts/iframe-resizer';
iframeResizer.init();

import * as tabsInit from './scripts/tabs-init';
tabsInit.init();

import * as imgTeaserSwiper from './scripts/img-teaser-swiper';
imgTeaserSwiper.init();

import * as accoTeaserSwiper from './scripts/acco-teaser-swiper';
accoTeaserSwiper.init();

import * as anchorNav from "./scripts/anchor-nav";
//
// import * as smoothScroll from './scripts/smooth-scroll';
// smoothScroll.init();

anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link" data-scroll-offset="180" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: true,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 300
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);


import * as leafletMap from './scripts/leaflet-map';
leafletMap.init();

import * as quickfinderRoom from './scripts/quickfinder-room';
quickfinderRoom.init();

import * as keepDropdownOpen from './scripts/keep-dropdown-open';
keepDropdownOpen.init();

import * as accoDetailAjaxFormQf from './scripts/acco-detail-ajax-form-qf';
accoDetailAjaxFormQf.init();

import * as popover from './scripts/popover';
popover.init();

import * as accordionTrustyou from './scripts/accordion-trust-you';
accordionTrustyou.init();

import * as datepickerRange from "./scripts/datepicker-range";
onFind('.js-datepicker-range', function (baseElement) {
    datepickerRange.createDatepicker(
        baseElement,
        {
            /*options*/
            mode: "range",
            allowInput: true,
            showMonths: 2,
            disableMobile: "false",
            minDate: "today",
        },{
            base: '.datepicker-range-container',
            input: '.js-datepicker-range__input',
            altField: '.js-datepicker-range__alt-field',
            altFieldRangeTo: '.js-datepicker-range__range-to-alt-field'
        }
    );
});
