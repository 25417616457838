"use strict";

import {findAll, findIn, closest, toggleClass, addClass, removeClass, on} from "@c0necto/elements-dom-utils";

export function init () {

    initShowAllContent();

    let showAllContentBtn = findAll('.js-show-all-content__btn');

    const btnClickHandler = (evt, btn) => {
        let showAllContentWrapper = closest('.js-show-all-content', btn);

        toggleClass('show', showAllContentWrapper);
    };

    showAllContentBtn.forEach((btn) => {
        on('click', (evt) => btnClickHandler(evt, btn), btn);
    });

}

function initShowAllContent() {

    let showAllContent = findAll('.js-show-all-content__content');

    showAllContent.forEach((_this) => {
        let showAllContentWrapper = closest('.js-show-all-content', _this);
        let maxHeight =  showAllContentWrapper.dataset.maxHeight || 85;

        if(_this.offsetHeight > maxHeight){
            addClass('show-all-content__max-height',showAllContentWrapper);
            _this.style.maxHeight = maxHeight;

            let showAllContentBtn =  findIn('.js-show-all-content__btn', showAllContentWrapper);
            showAllContentBtn.hidden = false;
        }else{

            findIn('.js-show-all-content__btn', closest('.js-show-all-content:not(.show-all-content__max-height)', _this)).hidden = true;
        }
    });


}