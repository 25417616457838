"use strict";

import {createAjaxForm} from "@c0necto/elements-ajax-form";
import {on, find, trigger, findIn, findAll} from "@c0necto/elements-dom-utils";
import {onFind} from "@c0necto/elements-init-modules-in-scope";
import {fetchKognitivFilterIds} from "./aa4y-single-enquiry";

export function init () {
    onFind('.js-ajax-form-detail-qf', function (element) {

        createAjaxForm(element, {
        }, {
            result: '.js-ajax-form-detail-qf__result',
            loading: '.js-ajax-form-detail-qf__loading',
            notifications: '.js-ajax-form-detail-qf__notifications',
            form: '.js-ajax-form-detail-qf__form',
            additionalForm: '.js-ajax-form-detail-qf__additional-form',
            errorArea: '.js-ajax-form-detail-qf__error-area',
            retry: '.js-ajax-form-detail-qf__retry',
            link: '.js-ajax-form-detail-qf__link',
            reset: '.js-ajax-form-detail-qf__reset',
        });


        on('fetched.ajax-form', (evt) => successHandler('params', evt.detail), element);
        on('reset.ajax-form', (evt) => resetHandler(evt), element);

        const successHandler = (evt, params) => {
            let filterIdsRequestUrlSingle = params.filterIdsRequestUrlSingle;

            _config.filterIdsRequestUrlSingle = params.filterIdsRequestUrlSingle;

            if (find('.js-aa4y-single-enquiry')){
                fetchKognitivFilterIds(find('.js-aa4y-single-enquiry'), filterIdsRequestUrlSingle);
            }

        };

        const resetHandler = (evt, params) => {
            trigger('submit', findIn('form',evt.target))
        };
    });

}
